import React from "react";
import { useSelector } from "react-redux";
import LazyLoad from "react-lazyload";
import Lifestyles from "./Lifestyles";
import HomeButtons from "./HomeButtons";
import Preview from "../../Preview";
import CdnImage from "../../CdnImage";
import HomeHero from "./HomeHero";

const Container = ({ indexData }) => {
    const nicheDomain2ndLevel = useSelector(
        (state) => state.root.styling.nicheDomain2ndLevel
    );
    const nichetypeSingular = useSelector(
        (state) => state.root.styling.nichetypeSingular
    );
    const touchDevice = useSelector((state) => state.root.isMobile);
    const aboutSection = useSelector(
        (state) => state.root.styling.microContentBlocks.about
    );
    const lifestylesCategories = {
        lakehomes: "12053",
        "beach-homes": "10",
    };
    const lifestylesCategory = lifestylesCategories[nicheDomain2ndLevel];

    return (
        <div data-testid="home">
            <HomeHero />
            <HomeWrapper
                key="home-buttons"
                className="bg-light"
                dataTest="home-buttons"
            >
                <HomeButtons />
            </HomeWrapper>
            {indexData?.listingsPreview?.results && (
                <HomeWrapper
                    title={indexData.listingsPreview?.dataSetTitle}
                    dataTest="featured"
                >
                    <Preview
                        className="preview"
                        listings={indexData.listingsPreview?.results}
                    />
                </HomeWrapper>
            )}
            <HomeWrapper
                key="home-content"
                className="bg-light"
                title={aboutSection.label}
                dataTest="home-content"
            >
                <div className="row">
                    <div className="col-md">
                        <div>
                            {aboutSection.text && (
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: aboutSection.text,
                                    }}
                                />
                            )}
                        </div>
                    </div>
                    <div className="col-md">
                        <CdnImage
                            className="w-100 rounded"
                            src={aboutSection.image}
                            alt={aboutSection.alt}
                        />
                    </div>
                </div>
            </HomeWrapper>
            {lifestylesCategory && (
                <HomeWrapper
                    key="home-lifestyles"
                    title={`${nichetypeSingular} Lifestyles`}
                    dataTest="lifestyles"
                >
                    <LazyLoad once>
                        <Lifestyles
                            touchDevice={touchDevice}
                            categories={lifestylesCategory}
                        />
                    </LazyLoad>
                </HomeWrapper>
            )}
        </div>
    );
};

const HomeWrapper = ({ children, className = "", dataTest, title }) => (
    <div className={className} data-test={dataTest}>
        <div className="container-lg">
            <div className="row g-0 py-4 mx-3 mx-lg-0">
                <div className="col g-0">
                    {title && <h2 className="text-uppercase">{title}</h2>}
                    {children}
                </div>
            </div>
        </div>
    </div>
);

export default Container;
